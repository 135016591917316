<template>
  <div class="quick" :class="{ mobile: isMobile }">
    <div class="qcontainer">
      <div
        class="quick-col"
        v-for="(item, index) in items"
        :key="index"
        @click="navigateTo(item.path, item.external)"
      >
        <div :class="['frame', `frame-${index + 1}`]">
          <div class="quick-div">
            <img class="img" :alt="`Img ${index + 1}`" :src="item.img" />
          </div>
          <div class="text-frame">
            <div class="text-wrapper">{{ item.text.main }}</div>
            <div class="text-wrapper-2">{{ item.text.sub }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "QuickBanner",
  data: () => ({
    items: [
      {
        img: require("@/assets/img/chobo.svg"),
        text: {
          main: "처음이라 어려울 때에는",
          sub: "초보자 가이드",
        },
        path: "/basic",
        external: false,
      },
      // {
      //   img: require("@/assets/img/vip.svg"),
      //   text: {
      //     main: "오직 회원만을 위한",
      //     sub: "VIP 회원혜택",
      //   },
      // },
      {
        img: require("@/assets/img/kakao_quick.svg"),
        text: {
          main: "채널추가하고 혜택받자!",
          sub: "퓨처온 카톡채널",
        },
        path: "/expertkakao",
        external: false,
      },
      {
        img: require("@/assets/img/academy.svg"),
        text: {
          main: "투자실력이 쑥쑥!",
          sub: "투자 아카데미",
        },
        path: "/academy",
        external: false,
      },
      {
        img: require("@/assets/img/inquiry.svg"),
        text: {
          main: "궁금한 사항은?",
          sub: "1:1 문의",
        },
        path: "https://open.kakao.com/o/sv7GvILg",
        external: true,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    navigateTo(path, external) {
      if (external) {
        window.open(path, "_blank");
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.quick {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 15rem;
  background-color: #f1f3f8;
  overflow: hidden;
}
.quick.mobile {
  padding: 2.5rem 0;
  height: auto;
  max-height: 100%;
  overflow: visible;
}

.quick .qcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 15rem;
  gap: 2.5rem;
}
.quick.mobile .qcontainer {
  height: auto;
  max-height: 100%;
  flex-wrap: wrap;
}

.quick-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 5.625rem;
  height: 100%;
  max-height: 5.625rem;
}

.quick .quick-col {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 14rem;
  height: 100%;
  max-height: 13.75rem;
  cursor: pointer;
}
.quick.mobile .quick-col {
  width: 100%;
  max-width: 6.25rem;
  height: 100%;
  max-height: 11.25rem;
  gap: 0.625rem;
}

.quick .frame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 1.565rem;
}
.quick.mobile .frame {
  gap: 0.625rem;
}

.text-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;
}
.quick.mobile .text-frame {
  height: 4.375rem;
  width: 6.25rem;
}

.quick .text-wrapper {
  white-space: nowrap;
  position: relative;
  width: 100%;
  height: 100%;
}
.quick.mobile .text-wrapper {
  font-size: 0.875rem;
  white-space: normal;
}

.quick .text-wrapper-2 {
  color: var(--grayscale-90);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
  width: 100%;
  white-space: nowrap;
}
.quick.mobile .text-wrapper-2 {
  font-size: 1.125rem;
  text-align: center;
  white-space: nowrap;
  line-height: 1.25rem;
  letter-spacing: -0.2em;
}
</style>
